import { useEffect, useRef, useState } from 'react';
import '@/public/assets/css/global.css';
import { jwtDecode } from 'jwt-decode';
import { getCookie, removeCookie } from '@/libs/helpers/Cookie';
import { useRouter } from 'next/router';
import libraryService from '@/libs/services/libraryService';

//context
import { GlobalContext } from '@/libs/context/GlobalContext';

//components
import { Layout } from '@/components/layout'
import { DownloadLoader } from '@/libs/shared/downloadLoader';
import { Loader } from '@/libs/shared/laoder';
import { isTokenExpired } from '@/libs/helpers/common';

//interfaces
import {IMaster} from '@/interfaces/IHomeMaster'
import React from 'react';
import Maintenance from '@/components/maintenance/Maintenance';
import ReLogin from '@/libs/shared/reLogin/ReLogin';

type props = {
  Component: any,
  pageProps: any,
  pageURL: string,
  masterApiData: IMaster,
  usertype: string
}
export default function MyApp({ Component, pageProps, pageURL, masterApiData, usertype }: props) {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [loginRole, setLoginRole] = useState("");
  const [syncing, setSyncing] = useState(false)
  const [startZooming, setStartZooming] = useState(false)
  const [isValid, setIsValid] = useState(false);
  const [module, setModule] = useState<string>("")
  const [enableEditing, setEnableEditing] = useState(true)
  const [incoId, setIncoId]=useState<string>("")
  const [downloading, setDownloading] = useState(false)
  const [boqVersions, setBoqVersions] = useState<any[]>([])
  const [showReqBtn, setShowReqBtn] = useState<Boolean>(false)
  const [showUpdateBtns, setShowUpdateBtns] = useState<Boolean>(false)
  const [slideCount, setSlideCount] = useState(0)
  const [dqcStage, setDqcStage] = useState<number>(1)
  const [reloadPopup, setReloadPopup] = useState(false)
  const [dqcStatus, setDqcStatus] = useState("")
  const [dqcStatusMessage, setDqcStatusMessage] = useState("")
  const [latestBoqVersion, setLatestBoqVersion] = useState<any>("")
  const [currentBoqVersion, setCurrentBoqVersion] = useState<any>("")
  const [selectedBoqVersion,setSelectedBoqVersion]=useState<any>("")
  const transformComponentRef = useRef(null);
  const pdfTransformComponentRef = useRef(null);
  const isLoginPage = pageURL.includes('/login');
  const [updateJsonError, setUpdateJsonError] = useState("")
  const isDownloadPdfPage = pageURL.includes('/downloadpdf');
  const [apiFlag, setApiFlag] = useState(false)
  const [commentFlag, setCommentFlag] = useState(true)
  const [commentsFlag, setCommentsFlag] = useState(false)
  const [enableImageDelete, setEnableImageDelete] = useState("")
  const query = pageURL.split('?')[1] || '';
  const [apiError, setApiError] = useState("");
  const [isOnline, setIsOnline] = useState(true);
  const [reLogin, setReLogin] = useState(false);
  const [zoomState, setZoomState] = useState({
    scale: 1,
    positionX: 0,
    positionY: 0,
    id: null,
    tool: "",
    points: [],
  });
  const [editorZoomState, setEditorZoomState] = useState({
    scale: 1,
    positionX: 0,
    positionY: 0,
    id: null,
    tool: "zoomEditor",
    lastScale: 1, 
    points: [],
  });
  const [pdfZoomState, setPdfZoomState] = useState({
    scale: 1,
    positionX: 0,
    positionY: 0,
    id: null,
    tool: "zoomPdf",
    lastScale: 1, 
    points: [],
  });
  const [selectedZoomTarget,setSelectedZoomTarget]=useState<any>({
    editor:false,
    pdf:true
  })
  const router = useRouter();
  const basePath = process.env.ENVIRONMENT == 'LOCAL' ? 'https://dev-drawing.interiorcompany.com' : process.env.SITE_URL || ''

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
      document.body.classList.add('overFlowHidden');
      document.body.style.cursor = 'wait'
    } else {
      document.body.classList.remove('overFlowHidden');
      document.body.style.cursor = 'default'
    }
  }, [loading])

  useEffect(() => {
    const authToken = getCookie('loginToken');
    if(!isLoginPage && !isDownloadPdfPage){
      setReLogin(isTokenExpired(authToken))
    }

    if ((!authToken) && !isLoginPage && !isDownloadPdfPage) {
        removeCookie('loginToken')
        localStorage.clear()
        router.push(`/login${query ? `?${query}` : ''}`);
    }

    if (masterApiData && Object.keys(masterApiData).length) {
      const userInfo = localStorage.getItem("userDetails");
      const userDetails = userInfo && JSON.parse(userInfo);
      if (userDetails?.roleIds?.includes(process.env.BLOCKS_ADMIN)) {
        setLoginRole("BLOCKS_ADMIN")
      }

      if ((masterApiData.userRole === "DQC" || masterApiData.userRole === "DESIGNER") || userDetails.roleIds.includes(process.env.BLOCKS_ADMIN)) {
        setRole(masterApiData.userRole ? masterApiData.userRole : usertype != "" ? usertype.toLocaleUpperCase() : '')
        setBoqVersions(masterApiData.versions);
        setShowReqBtn(masterApiData.showRaiseRequestBtn)
        setShowUpdateBtns(masterApiData.showUpdateStatusBtn)
        setDqcStage(masterApiData.dqcStage)
        setIncoId(masterApiData.incoId)
        setDqcStatus(masterApiData.dqcStatus ? masterApiData.dqcStatus: "Initial Design Phase")
        setDqcStatusMessage(masterApiData.popupMessage ? masterApiData.popupMessage : "" )
        const length = masterApiData.versions.length - 1
        const activeVersions = [masterApiData.versions[length]]
        setCurrentBoqVersion(masterApiData.versions[0])
        setLatestBoqVersion(activeVersions[0])
        setSelectedBoqVersion(activeVersions[0])
      } else {
        removeCookie('loginToken')
        localStorage.clear()
        setApiError('You are not authorized to log in. Please contact your administrator.')
        router.push(`/login${query ? `?${query}` : ''}`);
      }
    } else if (!isDownloadPdfPage) {
      removeCookie('loginToken')
      localStorage.clear()
      setApiError('You are not authorized to log in. Please contact your administrator.')
      router.push(`/login${query ? `?${query}` : ''}`);
    }

    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    handleOnlineStatusChange();

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };


  }, []);

  return (
    <GlobalContext.Provider value={{
      props: { setLoading,loading, pageURL, role, setRole, module, setModule, setBoqVersions, boqVersions, latestBoqVersion, setLatestBoqVersion, currentBoqVersion, setCurrentBoqVersion,selectedBoqVersion,setSelectedBoqVersion, showReqBtn, setShowReqBtn, showUpdateBtns, setShowUpdateBtns, dqcStage, setDqcStage, apiFlag, setApiFlag, basePath, dqcStatus, setDqcStatus, commentsFlag, setCommentsFlag, isValid, setIsValid, loginRole, setLoginRole, apiError, setApiError, isOnline, syncing, setSyncing, startZooming, setStartZooming, zoomState, setZoomState, updateJsonError, setUpdateJsonError, commentFlag, setCommentFlag, enableEditing, setEnableEditing, enableImageDelete, setEnableImageDelete, slideCount, setSlideCount, reloadPopup, setReloadPopup ,setDownloading,dqcStatusMessage, setDqcStatusMessage,incoId, setIncoId , pdfZoomState ,setPdfZoomState ,editorZoomState ,setEditorZoomState ,selectedZoomTarget , setSelectedZoomTarget,transformComponentRef , pdfTransformComponentRef}
    }}>
      {loading &&
        <Loader />
      }
      {downloading &&
        <DownloadLoader />
      }
      {reLogin && !isLoginPage && !isDownloadPdfPage &&
        <ReLogin />
      }
      {(!Boolean(Number(process.env.IS_UNDER_MAINTENANCE)) || loginRole=="BLOCKS_ADMIN") ? (
      (!isLoginPage && !isDownloadPdfPage) ? (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      ) : (
        <Component {...pageProps} />
      )
    ) : (
      <Maintenance/>
    )}
    </GlobalContext.Provider>
  )
}
MyApp.getInitialProps = async (pageContext: any) => {
  const ctx = pageContext.ctx;
  const siteUrl = process.env.SITE_URL || "";
  const pageUrl = ctx.asPath === '/' ? siteUrl : `${siteUrl}${ctx.asPath}`;
  const projectId = ctx.query.project || '';
  const usertype = ctx.query.usertype || 'designer';
  const userId = JSON.parse(ctx.req?.cookies?.loginToken || "{}").employeeId || "";

  let updatedMasterApiData = {};

  // Check if the route contains '/downloadpdf'
  if (!ctx.asPath.includes('/downloadpdf') && projectId && userId) {
    const masterApiData = await libraryService.getBOQVersions(
      projectId,
      usertype,
      userId
    );

    if (Object.keys(masterApiData).length > 0 && masterApiData.status === 1) {
      updatedMasterApiData = masterApiData.data;
    }
  }

  return {
    pageURL: pageUrl,
    masterApiData: updatedMasterApiData || {},
    usertype: usertype || ""
  };
};