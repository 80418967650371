import Image from "next/image";
import styles from "./Header.module.css";
import { useState, useEffect, useRef } from "react";
import { removeCookie } from '@/libs/helpers/Cookie'
import { useGlobalContext } from "@/libs/context/GlobalContext";
import { useRouter } from 'next/router';
import Select from "../select/Select";
import GlobalError from "@/components/popups/globalError/GlobalError"

export function Header() {
  const [open, setOpen] = useState(false);
  const { props: { latestBoqVersion,selectedBoqVersion, pageURL, setLoading, boqVersions, isValid, setIsValid, isOnline, syncing, enableEditing,incoId } } = useGlobalContext()
  const query = pageURL.split('?')[1] || '';
  const router = useRouter();
  const roomId = router.query.room
  const logoutRef = useRef<HTMLDivElement>(null);
  const isHomePage = pageURL.includes('/home');
  const [roomName, setRoomName] = useState("")
  const selectedBoqVersionId = router.query.version || selectedBoqVersion.id || ''
  const [selectedBoq, setselectedBoq] = useState<any>({
    id: -1,
    name: "NA",
    isActive: false
  })
  const defaultVersion = {
    id: -1,
    name: "NA",
    isActive: false
  }

  const logOut = () => {
    setLoading(true)
    removeCookie('loginToken')
    localStorage.clear()
    setLoading(false)
    router.push(`/login${query ? `?${query}` : ''}`);
  }

  useEffect(() => {
    if (boqVersions.length > 0) {
      const selectedVersion = boqVersions.find((version: any) => version.id == selectedBoqVersionId);
      setselectedBoq(selectedVersion);
    } else {
      setselectedBoq(defaultVersion)
    }
  }, [selectedBoqVersion])

  useEffect(() => {
    const room = localStorage.getItem(`roomName_${roomId}`)
    if (room) {
      setRoomName(room)
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const goToHome = () => {
    const params = query.split('&').map(param => param.split('='));
    const projectParam = params.find(([key]) => key === 'project');
    const userTypeParam = params.find(([key]) => key === 'usertype');
  
    // Start with the base path
    let newPath = '/home';
  
    // Add parameters if they exist
    const queryParams = [];
    if (projectParam) {
      queryParams.push(projectParam.join('='));
    }
    if (userTypeParam) {
      queryParams.push(userTypeParam.join('='));
    }
  
    // Construct the new URL with the parameters
    if (queryParams.length > 0) {
      newPath += '?' + queryParams.join('&');
    }
  
    // Navigate to the new URL
    router.push(newPath);
  }

  return (
    <div className={styles.logoHeadBox}>
      <div className={styles.leftLogos}>
        <figure className={`${styles.logoBx} pointer`} onClick={() => goToHome()}>
          <Image
            src="/assets/images/Blocks-dummy-logo.svg"
            width={36}
            height={30}
            alt="logo"
          />
        </figure>
        <figure className={styles.logoBx}>
          <Image
            src="/assets/images/DQC.svg"
            alt="logo"
            width={40}
            height={30}
            className="ml5"
          />
        </figure><div className="dFlex flexCol alignCenter" style={{ borderLeft: "0.5px solid #b0b0b0", height: "50px",justifyContent:"center" }}>
          {!isHomePage && roomName && <div className={styles.roomName}>{roomName}</div>}
          {<div className={styles.incoId}>{incoId}</div>}
        </div>
        {!isHomePage && enableEditing && <div className={styles.labels}>View Only Mode</div>}
      </div>
      <div className="dFlex alignCenter">
        {!isHomePage && <><figure className={styles.cloudSync}>
          <Image
            src={`/assets/images/${syncing ? 'cloud_not_sync.svg' : 'cloud_sync.svg'}`}
            alt="logo"
            width={20}
            height={30}
          />
        </figure> <span className={styles.syncStatus}>{syncing ? 'Syncing...' : 'Saved...'}</span></>}
        {isHomePage && <div className={`${styles.boqVersion} mr10`}>
          <Select type="Versions" data={boqVersions} selectedName={selectedBoqVersion.name} />
        </div>}
        {!isHomePage && selectedBoq?.name && <div className={styles.boqVersionBox}>{selectedBoq.name}</div>}
        {!isHomePage && <figure className={styles.cloudSync}>
          <Image
            src={`/assets/images/net-${isOnline ? "online" : "offline"
              }.svg`}
            width={20}
            height={18}
            alt="internet"
          />
        </figure>}
        <div
          className={styles.bg_col}
          onClick={() => setOpen(!open)}
          ref={logoutRef}
          title="LogOut"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.3 6.133a3.2 3.2 0 1 1 6.4 0 3.2 3.2 0 0 1-6.4 0zm3.2-1.6a1.6 1.6 0 1 0 0 3.2 1.6 1.6 0 0 0 0-3.2z"
              fill="#666"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.012 13.798A8 8 0 1 1 2.987 2.203a8 8 0 0 1 11.026 11.595zm-10.486-1.77a6.4 6.4 0 1 1 9.948 0A7.5 7.5 0 0 0 8.5 10.133a7.5 7.5 0 0 0-4.974 1.895zm1.16 1.112A6.371 6.371 0 0 0 8.5 14.4c1.43 0 2.749-.469 3.814-1.26A5.9 5.9 0 0 0 8.5 11.733a5.899 5.899 0 0 0-3.814 1.407z"
            />
          </svg>
        </div>
      </div>

      {open && (
        <ul className={styles.login_signUp}>
          <li className="font10" onClick={logOut}>Logout</li>
        </ul>
      )}
      {isValid &&
        <GlobalError setPopupMessage={setIsValid} message="ProjectId or RoomId or VersionId Missing" svgIcon="requestRaiseError" />
      }
    </div>
  );
}
