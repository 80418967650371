import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { setCookie } from "./Cookie";
import { decodeToken, formDataToJsonString, sendMail } from "./common";
import { config } from "process";

abstract class HttpClient {
  public readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse);
  };

  private _handleResponse = ({
    data,
    status,
    statusText,
    headers,
    config,
  }: AxiosResponse) => ({ data, status, statusText, headers, config });

  protected _handleError = (error: AxiosError) => {
    if (error.config)
      console.error(`API URL -  ${error.config.baseURL}${error.config.url}`);
    console.error(`AxiosError - ${error.code} : ${error.message}`);
    return error;
  };
}
class LibraryApiClient extends HttpClient {
  private static api?: AxiosInstance;
  private constructor() {
    const baseURL = process.env.LIBRARY_API_BASE_URL || "";
    super(baseURL);
  }
  public static getInstance() {
    if (!this.api) {
      this.api = new LibraryApiClient().instance;
      // this.api.interceptors.request.use((request:AxiosRequestConfig):InternalAxiosRequestConfig => {
      //   request.withCredentials=true;
      //   request.headers={
      //     ...request.headers,
      //     Authorization:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxMDA0OTIiLCJ1bmlxdWVfbmFtZSI6Ikphc3dpbmRlciBTaW5naCIsIkFwcFR5cGUiOiJUREQiLCJuYmYiOjE3MjUzNDY3NzcsImV4cCI6MTcyNTk1MTU3NywiaWF0IjoxNzI1MzQ2Nzc3fQ.CUyJKiOszD6KOFVuLO1p8z97r0_icx6S__uphA6OLY8"
      //   }
      //   return request as InternalAxiosRequestConfig
      // })
      this.api.interceptors.response.use((res: AxiosResponse): any => {        
        const userId =  res?.config?.headers?.Authorization && decodeToken(res.config.headers.Authorization.toString())?.UserId || "";
        if (res.status != 200){
          let fileData: File | null = null;
          if (
            res.config.data instanceof FormData &&
            res.config.data.has('File')
          ) {
            fileData = res.config.data.get('File') as File;
          }
          const body = `Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n UserId = ${userId}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${formDataToJsonString(res.config.data || "")}\n`;
          sendMail(body,fileData)
        }
          return res;
        },(err)=>{
          const res = err.response
          const userId =  res?.config?.headers?.Authorization && decodeToken(res.config.headers.Authorization.toString())?.UserId || "";
          let fileData: File | null = null;
          if (
            res.config.data instanceof FormData &&
            res.config.data.has('File')
          ) {
            fileData = res.config.data.get('File') as File;
          }
          const body = `Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n AxiosError - ${err.code} : ${err.message}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n UserId = ${userId}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${formDataToJsonString(res.config.data || "")}\n`;
          sendMail(body,fileData)
          return err;
        });
    }
    return this.api;
  }
}
class DrawingApiClient extends HttpClient {
  private static api?: AxiosInstance;
  private constructor() {
    const baseURL = process.env.DRAWING_API_BASE_URL || "";
    super(baseURL);
  }
  public static getInstance() {
    if (!this.api) {
      this.api = new DrawingApiClient().instance;
      this.api.interceptors.response.use((res: AxiosResponse): any => {        
        const userId =  res?.config?.headers?.Authorization && decodeToken(res.config.headers.Authorization.toString())?.UserId || "";
        if (res.status != 200){
          let fileData: File | null = null;
          if (
            res.config.data instanceof FormData &&
            res.config.data.has('File')
          ) {
            fileData = res.config.data.get('File') as File;
          }
          const body = `Drawing Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n UserId = ${userId}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${formDataToJsonString(res.config.data || "")}\n`;
          sendMail(body,fileData)
        }
          return res;
        },(err)=>{
          const res = err.response
          const userId =  res?.config?.headers?.Authorization && decodeToken(res.config.headers.Authorization.toString())?.UserId || "";
          let fileData: File | null = null;
          if (
            res.config.data instanceof FormData &&
            res.config.data.has('File')
          ) {
            fileData = res.config.data.get('File') as File;
          }
          const body = `Drawing Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n AxiosError - ${err.code} : ${err.message}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n UserId = ${userId}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${formDataToJsonString(res.config.data || "")}\n`;
          sendMail(body,fileData)
          return err;
        });
    }
    return this.api;
  }
}
class ApiClient extends HttpClient {
  private static api?: AxiosInstance;
  private constructor() {
    const baseURL = process.env.SITE_URL || "";
    super(baseURL);
  }

  public static getInstance() {
    if (!this.api) {
      this.api = new ApiClient().instance;
      this.api.interceptors.request.use(
        (config: AxiosRequestConfig): InternalAxiosRequestConfig => {
          config.withCredentials = true;
          let _date = new Date();
          _date.setMinutes(_date.getMinutes() + 10);
          const token = _date.getTime().toString();
          setCookie("authtoken", token);
          config.headers = {
            ...config.headers,
            token: token,
          };
          return config as InternalAxiosRequestConfig;
        }
      );
    }
    return this.api;
  }
}

const API = ApiClient.getInstance();
const LibraryAPI = LibraryApiClient.getInstance();
const DrawingAPI = DrawingApiClient.getInstance();


export { API, LibraryAPI,DrawingAPI };
