import React from "react";
import styles from "./ReLogin.module.css";
import { useRouter } from "next/router";
import { removeCookie } from '@/libs/helpers/Cookie';


export default function ReLogin() {
    const router = useRouter();
    const pId=router.query.project
  
    const reLogin = () => {
        removeCookie('loginToken')
        localStorage.clear()
        router.push(`/login?project=${pId}`);
    };

    return (
        <div className={styles.successWrapper}>
            <div className={styles.boundary}>
                <div className={styles.upperBox}>
                    <figure>
                        <img
                            className="img-responsive"
                            src="/assets/images/requestRaiseError.svg"
                            alt="Request Raise Error"
                        />
                    </figure>
                    <div className={styles.message}>Your session has expired. Please log in again to continue.</div>
                </div>
                <div className={styles.lowerBox}>
                    <button className={styles.accept} onClick={reLogin}>Re Login</button>
                </div>
            </div>
        </div>
    );
}