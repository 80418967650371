import React from "react";
import styles from './GlobalError.module.css';
import { useRouter } from "next/router";

type successProps = {
    message: String;
    svgIcon:String;
    btnType?:String;
    setPopupMessage:Function
};
export default function Success({ message, svgIcon, setPopupMessage }: successProps) {
    const router=useRouter()
    return (
        <div className={styles.successWrapper}>
            <div className={styles.boundary}>
                <div className={styles.cross} onClick={() => {
                    setPopupMessage('')
                    router.reload()
                    }}>X</div>
                <div className={styles.upperBox}>
                    <figure>
                        <img
                            className="img-responsive"
                            src={`assets/images/${svgIcon}.svg`}
                            alt=""
                        />
                    </figure>
                    <div className={styles.message}>{message}</div>
                </div>
            </div>
        </div>
    );
}
