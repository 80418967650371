import React from 'react'
import styles from './Maintenance.module.css'

export default function Maintenance() {
  return (
    <div className={styles.maintenanceContainer}>
      <div className={styles.content}>
        <img 
          src="/assets/images/maintenance.svg" 
          alt="Maintenance Icon" 
          className={styles.icon}
        />
        <h1 className={styles.label1}>We are Under Maintenance</h1>
      </div>
    </div>
  )
}
